//menu on bottom animation
@keyframes dropdownFadeIn {
  0% {opacity: 0; margin-top: 0;}
  100% {opacity: 1; margin-top: $dropdown-menu-vertical-shift;}
}
//menu on top animation
@keyframes dropdownFadeInTop {
  0% {opacity: 0; margin-top: 0;}
  100% {opacity: 1; margin-top: -$dropdown-menu-vertical-shift;}
}

.dropdown, .btn-group {
  //menu fade in
  &.show {
    .dropdown-menu {
      //menu on bottom
      &[x-placement='bottom-start'] {
        animation: dropdownFadeIn forwards $dropdown-menu-animation-speed;
      }
      //menu on top
      &[x-placement='top-start'] {
        animation: dropdownFadeInTop forwards $dropdown-menu-animation-speed;
      }
    }
  }
}

.btn-dropdown {
  color: $dropdown-custom-button-color;
  padding: $dropdown-custom-button-padding;
  background-color: $dropdown-custom-button-background;
  font-size: $dropdown-custom-button-font-size;
  border-radius: 0;
  &:not(:disabled):not(.disabled):active {
    box-shadow: none;
  }
}
.btn-dropdown, .dropdown-toggle {
  &:after {
    content: "";
    speak: none;
    border: none;
    display: inline;
    margin: 0;
    vertical-align: bottom;
    width: auto;
    height: auto;
  }
  .icon-expand {
    transition: transform $dropdown-menu-animation-speed;
  }
  .icon.icon-xs{
    transition: transform $dropdown-menu-animation-speed;
    width: 18px;
    height: 18px;
    transform: translateY(-1px);
  }
  &[aria-expanded=true] .icon-expand {
    transform: scaleY(-1);
  }
}

.dropdown-menu {
  border-radius: 0 0 $dropdown-menu-radius $dropdown-menu-radius;
  //fullwidth menu
  &.full-width {
    width: 100%;
    .link-list li {
      display: inline-block;
      width: auto;
      &:hover,
      &:focus {
        background: none;
        text-decoration: underline;
      }
    }
  }

  //link list finetuning
  .link-list {
    margin-bottom: 0;
    //header
    h3 {
      line-height: $link-list-line-height;
      margin-bottom: 0;
    }
  }

  //notch
  &:before {
    content: '';
    position: absolute;
    top: -$dropdown-menu-notch-base-size;
    left: $dropdown-menu-notch-position-x;
    width: $dropdown-menu-notch-base-size*3;
    height: $dropdown-menu-notch-base-size*3;
    border-radius: $dropdown-menu-radius;
    background-color: $dropdown-bg;
    transform: rotate(45deg);
  }

  //top position
  &[x-placement='top-start'] {
    border-radius: $dropdown-menu-radius $dropdown-menu-radius 0 0;
    //notch
    &:before {
      bottom: -$dropdown-menu-notch-base-size;
      top: auto;
    }
  }

  //dark version
  &.dark {
    background-color: $neutral-1-a8;
    .link-list-wrapper ul {
      span.divider {
        background: #2E465E;
      }
      li {
        //link list colors and hovers
        a span, a:hover span, h3, i {
          color: #fff;
        }
        a:not(.active):not(.disabled):hover i {
          color: $analogue-2-a4;
        }
        a.disabled span {
          color: $neutral-1-a4;
        }
      }
    }
    //notch
    &:before {
      background-color: $neutral-1-a8;
    }
  }
}
