//mobile
.it-header-navbar-wrapper{
	
	nav{
		background: transparent;
		.navbar-collapsable{
			
		}
	}
}
//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {
  
}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {
	
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
	.it-header-navbar-wrapper{
		background: $header-center-bg-color;
		.nav-item.megamenu>a.dropdown-toggle:before{
			bottom:- $header-nav-icon-arrow-megamenu-distance;
		}
		nav{
			.navbar-collapsable{
				.menu-wrapper{
					background: transparent;
					.navbar-nav{
						padding:0;
						
					}
				}
			}
		}
	}
}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}
