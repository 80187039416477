// start override
.theme-dark{
	&.it-timepicker-wrapper{
		.calendar-input-container{
			
			&:after{
				content: '';
				background:$dp-label-color;
			}
			input.form-control{
				background: transparent;
				&::placeholder{
					color: $dp-label-color;
				}
			}
			.btn-time{
				background: transparent;
				svg{
					
				}
			}
		}
		.time-spinner{
			
		}
		.spinner-control{
			background:$primary-a6;
			.spinner{
			
				&:not(:last-child){
					
				}
				.button-wrapper{
				
					button{
						
						.icon-up{
							border-color: transparent transparent #FFF transparent;
						}
						.icon-down{
							border-color: #FFF transparent transparent transparent;
						}
					}
				}
			}
			input{
				color: #FFF;
				border-bottom: 1px solid #FFF;
				background: transparent;
			}
		}
	}
	
}
