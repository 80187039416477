// primary color ---------------------------------
.primary-bg{
	background-color:$color-primary; 
}
.primary-color{
	color:$color-primary; 
}
.primary-border-color{
	border-color:$color-primary !important;
	&.border{
		border-color:$color-primary !important;
	}
}

// white
.white-bg{
	background-color:$white; 
}
.white-color{
	color:$white; 
}
.white-border-color-{
	border-color:$white !important;
	&.border{
		border-color:$white !important;
	}
}

// primary colors tinte e ombre------------------

$colorlist : 
	$primary-a1
	$primary-a2
	$primary-a3
	$primary-a4
	$primary-a5
	$primary-a6
	$primary-a7
	$primary-a8
	$primary-a9
	$primary-a10
	$primary-a11
	$primary-a12;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.primary-bg-a#{$i}{
		background-color:$color; 
	}
	.primary-color-a#{$i}{
		color:$color;
	}
	.primary-border-color-a#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}
// primary colors toni------------------
$colorlist : 
	$primary-b1
	$primary-b2
	$primary-b3
	$primary-b4
	$primary-b5
	$primary-b6
	$primary-b7
	$primary-b8;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.primary-bg-b#{$i}{
		background-color:$color; 
	}
	.primary-color-b#{$i}{
		color:$color; 
	}
	.primary-border-color-b#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		} 
	}
}
// primary colors saturazione------------------
$colorlist : 
	$primary-c1
	$primary-c2
	$primary-c3
	$primary-c4
	$primary-c5
	$primary-c6
	$primary-c7
	$primary-c8
	$primary-c9
	$primary-c10
	$primary-c11
	$primary-c12; 

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.primary-bg-c#{$i}{
		background-color:$color; 
	}
	.primary-color-c#{$i}{
		color:$color; 
	}
	.primary-border-color-c#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		} 
	}
}
// accent colors-----------------------------------------------------------
.analogue-1-bg{
	background-color:$analogue-1; 
}
.analogue-1-color{
		color:$analogue-1; 
}
.analogue-1-border-color-{
	border-color:$analogue-1 !important;
	&.border{
		border-color:$analogue-1 !important;
	}
}
// accent colors tinte e ombre------------------
// analog 1
$colorlist : 
	$analogue-1-a1
	$analogue-1-a2
	$analogue-1-a3
	$analogue-1-a4
	$analogue-1-a5
	$analogue-1-a6
	$analogue-1-a7
	$analogue-1-a8
	$analogue-1-a9
	$analogue-1-a10
	$analogue-1-a11
	$analogue-1-a12;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.analogue-1-bg-a#{$i}{
		background-color:$color; 
	}
	.analogue-1-color-a#{$i}{
		color:$color; 
	}
	.analogue-1-border-color-a#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}
// analog 2
.analogue-2-bg{
	background-color:$analogue-2; 
}
.analogue-2-color{
		color:$analogue-2; 
}
.analogue-2-border-color-{
	border-color:$analogue-2 !important;
	&.border{
		border-color:$analogue-2 !important;
	}
}
$colorlist : 
	$analogue-2-a1
	$analogue-2-a2
	$analogue-2-a3
	$analogue-2-a4
	$analogue-2-a5
	$analogue-2-a6
	$analogue-2-a7
	$analogue-2-a8
	$analogue-2-a9
	$analogue-2-a10
	$analogue-2-a11
	$analogue-2-a12;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.analogue-2-bg-a#{$i}{
		background-color:$color; 
	}
	.analogue-2-color-a#{$i}{
		color:$color; 
	}
	.analogue-2-border-color-a#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// complementary 1
.complementary-1-bg{
	background-color:$complementary-1; 
}
.complementary-1-color{
		color:$complementary-2; 
}
.complementary-1-border-color-{
	border-color:$complementary-1 !important;
	&.border{
		border-color:$complementary-1 !important;
	}
}
$colorlist : 
	$complementary-1-a1
	$complementary-1-a2
	$complementary-1-a3
	$complementary-1-a4
	$complementary-1-a5
	$complementary-1-a6
	$complementary-1-a7
	$complementary-1-a8
	$complementary-1-a9
	$complementary-1-a10
	$complementary-1-a11
	$complementary-1-a12;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.complementary-1-bg-a#{$i}{
		background-color:$color; 
	}
	.complementary-1-color-a#{$i}{
		color:$color; 
	}
	.complementary-1-border-color-a#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// complementary 2
.complementary-2-bg{
	background-color:$complementary-2; 
}
.complementary-2-color{
		color:$complementary-2; 
}
.complementary-2-border-color-{
	border-color:$complementary-2 !important;
	&.border{
		border-color:$complementary-2 !important;
	}
}
$colorlist : 
	$complementary-2-a1
	$complementary-2-a2
	$complementary-2-a3
	$complementary-2-a4
	$complementary-2-a5
	$complementary-2-a6
	$complementary-2-a7
	$complementary-2-a8
	$complementary-2-a9
	$complementary-2-a10
	$complementary-2-a11
	$complementary-2-a12;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.complementary-2-bg-a#{$i}{
		background-color:$color; 
	}
	.complementary-2-color-a#{$i}{
		color:$color; 
	}
	.complementary-2-border-color-a#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// complementary 3
.complementary-3-bg{
	background-color:$complementary-3; 
}
.complementary-3-color{
		color:$complementary-3; 
}
.complementary-3-border-color-{
	border-color:$complementary-3 !important;
	&.border{
		border-color:$complementary-3 !important;
	}
}
$colorlist : 
	$complementary-3-a1
	$complementary-3-a2
	$complementary-3-a3
	$complementary-3-a4
	$complementary-3-a5
	$complementary-3-a6
	$complementary-3-a7
	$complementary-3-a8
	$complementary-3-a9
	$complementary-3-a10
	$complementary-3-a11
	$complementary-3-a12;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.complementary-3-bg-a#{$i}{
		background-color:$color; 
	}
	.complementary-3-color-a#{$i}{
		color:$color; 
	}
	.complementary-3-border-color-a#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// accent colors toni------------------
// analog 1
.analogue-1-bg{
	background-color:$analogue-1; 
}
.analogue-1-color{
		color:$analogue-1; 
}
.analogue-1-border-color-{
	border-color:$analogue-1 !important;
	&.border{
		border-color:$analogue-1 !important;
	}
}
$colorlist : 
	$analogue-1-b1
	$analogue-1-b2
	$analogue-1-b3
	$analogue-1-b4
	$analogue-1-b5
	$analogue-1-b6
	$analogue-1-b7
	$analogue-1-b8;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.analogue-1-bg-b#{$i}{
		background-color:$color; 
	}
	.analogue-1-color-b#{$i}{
		color:$color; 
	}
	.analogue-1-border-color-b#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// analog 2
.analogue-2-bg{
	background-color:$analogue-2; 
}
.analogue-2-color{
	color:$analogue-2;
}
.analogue-2-border-color-{
	border-color:$analogue-2 !important;
	&.border{
		border-color:$analogue-2 !important;
	}
}
$colorlist : 
	$analogue-2-b1
	$analogue-2-b2
	$analogue-2-b3
	$analogue-2-b4
	$analogue-2-b5
	$analogue-2-b6
	$analogue-2-b7
	$analogue-2-b8;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.analogue-2-bg-b#{$i}{
		background-color:$color; 
	}
	.analogue-2-color-b#{$i}{
		color:$color; 
	}
	.analogue-2-border-color-b#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// complementary 1
$colorlist : 
	$complementary-1-b1
	$complementary-1-b2
	$complementary-1-b3
	$complementary-1-b4
	$complementary-1-b5
	$complementary-1-b6
	$complementary-1-b7
	$complementary-1-b8;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.complementary-1-bg-b#{$i}{
		background-color:$color; 
	}
	.complementary-1-color-b#{$i}{
		color:$color; 
	}
	.complementary-1-border-color-b#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		} 
	}
}

// complementary 2
$colorlist : 
	$complementary-2-b1
	$complementary-2-b2
	$complementary-2-b3
	$complementary-2-b4
	$complementary-2-b5
	$complementary-2-b6
	$complementary-2-b7
	$complementary-2-b8;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.complementary-2-bg-b#{$i}{
		background-color:$color; 
	}
	.complementary-2-color-b#{$i}{
		color:$color; 
	}
	.complementary-2-border-color-b#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// complementary 3
$colorlist : 
	$complementary-3-b1
	$complementary-3-b2
	$complementary-3-b3
	$complementary-3-b4
	$complementary-3-b5
	$complementary-3-b6
	$complementary-3-b7
	$complementary-3-b8;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.complementary-3-bg-b#{$i}{
		background-color:$color; 
	}
	.complementary-3-color-b#{$i}{
		color:$color; 
	}
	.complementary-3-border-color-b#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// neutral 1
.neutral-1-bg{
	background-color:$neutral-1; 
}
.neutral-1-color{
	color:$neutral-1;
}
.neutral-1-border-color-{
	border-color:$neutral-1 !important;
	&.border{
		border-color:$neutral-1 !important;
	} 
}

$colorlist : 
	$neutral-1-a1
	$neutral-1-a2
	$neutral-1-a3
	$neutral-1-a4
	$neutral-1-a5
	$neutral-1-a6
	$neutral-1-a7
	$neutral-1-a8
	$neutral-1-a9
	$neutral-1-a10;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i);
	.neutral-1-bg-a#{$i}{
		background-color:$color; 
	}
	.neutral-1-color-a#{$i}{
		color:$color; 
	}
	.neutral-1-border-color-a#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// neutral 2
.neutral-2-bg{
	background-color:$neutral-2; 
}
.neutral-2-color{
	color:$neutral-2;
}
.neutral-2-border-color-{
	border-color:$neutral-2;  
}

$colorlist : 
	$neutral-2-b1
	$neutral-2-b2
	$neutral-2-b3
	$neutral-2-b4
	$neutral-2-b5
	$neutral-2-b6
	$neutral-2-b7;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i); 
	.neutral-2-bg-b#{$i}{
		background-color:$color; 
	}
	.neutral-2-color-b#{$i}{
		color:$color; 
	}
	.neutral-2-border-color-b#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

$colorlist : 
	$neutral-2-a1
	$neutral-2-a2
	$neutral-2-a3
	$neutral-2-a4
	$neutral-2-a5
	$neutral-2-a6
	$neutral-2-a7;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i); 
	.neutral-2-bg-a#{$i}{
		background-color:$color; 
	}
	.neutral-2-color-a#{$i}{
		color:$color; 
	}
	.neutral-2-border-color-a#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// light greys a
$colorlist : 
	$lightgrey-a1
	$lightgrey-a2
	$lightgrey-a3
	$lightgrey-a4;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i); 
	.lightgrey-bg-a#{$i}{
		background-color:$color; 
	}
	.lightgrey-color-a#{$i}{
		color:$color; 
	}
	.lightgrey-border-color-a#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// light greys b
$colorlist : 
	$lightgrey-b1
	$lightgrey-b2
	$lightgrey-b3
	$lightgrey-b4;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i); 
	.lightgrey-bg-b#{$i}{
		background-color:$color; 
	}
	.lightgrey-color-b#{$i}{
		color:$color; 
	}
	.lightgrey-border-color-b#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

// light greys c
$colorlist : 
	$lightgrey-c1
	$lightgrey-c2;

@for $i from 1 through length($colorlist){
	$color: nth($colorlist, $i); 
	.lightgrey-bg-c#{$i}{
		background-color:$color; 
	}
	.lightgrey-color-c#{$i}{
		color:$color; 
	}
	.lightgrey-border-color-c#{$i}{
		border-color:$color !important;
		&.border{
			border-color:$color !important;
		}
	}
}

