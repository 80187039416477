//mobile
//menu on bottom animation
@keyframes megamenuFadeIn {
  0% {
    opacity: 0;
    margin-top: $dropdown-menu-vertical-shift;
  }
  100% {
    opacity: 1;
    margin-top: $dropdown-menu-vertical-shift;
  }
}

.navbar {
  background: $navigation-bg-color $navbar-bg-color;
  // overlay div
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: $navigation-sidebar-bg-color-mobile;
    display: none;
  }

  // navbar mobile
  .navbar-collapsable {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    //background: $navigation-sidebar-bg-color-mobile;
    display: none;

    .menu-wrapper {
      background: $navigation-bg-color;
      position: absolute;
      top: 0;
      bottom: 0;
      right: $navigation-h-padding * 2;
      left: 0;
      pointer-events: all;
      overflow-y: auto;
      transform: translateX(-100%);
      //- transizione de definire
      transition: all 0.2s cubic-bezier(.29, .85, .5, .99);

      .navbar-nav {
        padding: $navigation-h-padding 0 0;
        overflow: hidden;

        .dropdown-menu {
          padding-bottom: 0;
          // link list variation
          .link-list {
            h3 {

            }
          }
        }
      }
    }

    /*lista menu*/
    .navbar-nav {
      padding: $navigation-h-padding 0;

      li {
        a.nav-link {
          color: $navigation-link-color;
          font-weight: 600;
          padding: $navigation-v-padding $navigation-h-padding;
          position: relative;

          &.dropdown-toggle {
            svg {
              fill: $navbar-bg-color;
              transition: all 0.3s;

            }

            &[aria-expanded="true"] {
              .icon {
                transform: scaleY(-1);
              }
            }
          }

          &.active {
            border-left: $navigation-link-active-bar-size solid $navigation-link-active-bar-color;
          }

          &:hover:not(.active) {
            text-decoration: underline;
          }
        }
      }
    }

    &.expanded {
      .menu-wrapper {
        transform: translateX(0);
        transition: all 0.3s cubic-bezier(.29, .85, .5, .99);
      }

      .close-div {
        transform: translateX(0);
      }
    }
  }

  //wrapper pulsante di chiusura menu
  .close-div {
    transform: translateX(-100%);
    padding: $navigation-h-padding 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: $navigation-bg-color;
    z-index: 10;
    pointer-events: none;
    //- transizione de definire
    transition: all 0.3s cubic-bezier(.1, .57, .4, .97);
    // pulsante di chiusura menu
    .close-menu {
      background: transparent;
      pointer-events: all;
      color: $navigation-close-button-text-color;
      text-align: center;
      font-size: $navigation-close-button-text-size;
      text-transform: uppercase;
      padding: 0;
      float: right;

      span {
        display: block;
        text-align: center;
        font-size: $navigation-close-button-icon-size;

        &:before {
          margin-right: 0;
        }
      }
    }
  }

  // Dropdown style tipo collapse
  .dropdown-menu {
    background: transparent;
    box-shadow: none;
    padding-top: 0;

    &:before {
      display: none;
    }

    .link-list-wrapper {
      padding-left: $navigation-h-padding;
      padding-right: $navigation-h-padding;
      margin-bottom: $navigation-h-padding;

      ul {
        li {
          a {
            &.list-item.active {
              color: $primary-b3;
              text-decoration: underline;

              span {
                color: $primary-b3;
              }
            }

            span {
              color: $navigation-link-color;
            }

            i {
              color: $navigation-link-color;
            }

            h3 {
              //color: $navigation-link-color;
            }
          }

          h3 {
            // color: $navigation-link-color;
            text-transform: uppercase;
            font-size: $megamenu-heading-text-size;
            font-weight: $megamenu-heading-font-weight;
            letter-spacing: $megamenu-heading-letter-spacing;
          }

          .divider {
            width: $megamenu-link-small-line-width;
            background: $neutral-2;
            margin-left: $navigation-h-padding;
          }
        }
      }
    }
  }
}

.custom-navbar-toggler {
  background: none;
  border: none;
  cursor: pointer;

  span {
    color: $navigation-toggle-button-icon-color;
    font-size: $navigation-toggle-button-icon-size;
  }

  svg {
    fill: $navigation-toggle-button-icon-color;
    width: $navigation-hamburger-size;
    height: $navigation-hamburger-size;
    display: block;
  }
}

//reset icon on megamenu
.nav-item.megamenu > a:before {
  display: none;
}

//-------------------INLINE MENU---------------------
.inline-menu {
  .link-list-wrapper {
    .link-list {
      position: relative;

      li {
        a {
          position: relative;

          i {
            color: $primary-a3;
          }

          &:after {
            content: '';
            display: block;
            width: $inline-menu-line-width;
            background: $color-primary;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            transform-origin: center;
            transform: scaleY(0);
            transition: all 0.3s;
            z-index: 1;
          }

          &.active {
            span {
              color: $color-primary;
              text-decoration: none;
            }
          }

          &[aria-expanded = "true"] {
            &:after {
              transform: scaleY(1);
            }
          }
        }
      }

      &:after {
        content: '';
        display: block;
        width: $inline-menu-line-width;
        background: linear-gradient(to bottom, rgba($neutral-2-a1, 1) 0%, rgba($neutral-2-a1, .3) 100%);
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
      }
    }
  }
}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {

}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .navbar {
    background: $navigation-bg-color-desktop;
    padding: 0;
    // navbar mobile
    .navbar-collapsable {
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      bottom: auto;
      z-index: auto;
      background: none;
      display: block !important; //-sovrascrive l'inline style di jquery
      transition: none;

      .overlay {
        display: none !important;
      }

      // menu vrapper
      .menu-wrapper {
        position: inherit;
        top: auto;
        bottom: auto;
        right: auto;
        left: auto;
        overflow-y: visible;
        transform: none;
        transition: none;
        background: $navigation-bg-color-desktop;

        .navbar-nav {
          margin-top: 0;
          padding: 0 $navigation-h-padding;
          overflow: inherit;

          .dropdown-menu {
            padding-bottom: $link-list-footer-distance-bottom;
          }
        }
      }

      &.collapsed {

      }

      /*lista menu*/
      .navbar-nav {
        margin-top: 0;
        padding: 0 $navigation-h-padding;

        li {
          a.nav-link {
            font-weight: 400;
            padding: $navigation-v-padding $navigation-h-padding;
            color: $navigation-link-color-desk;

            &.dropdown-toggle {
              svg {
                fill: $navigation-bg-color;
              }
            }

            &.active {
              border-left: 0;
              border-bottom: $navigation-link-active-bar-size solid $navigation-link-active-bar-color-desk;
              background: transparent;
            }

            &.disabled {
              opacity: $navigation-disabled-item-opacity;
              cursor: not-allowed;
            }
          }
        }
      }
    }

    //wrapper pulsante di chiusura menu
    .close-div {
      display: none;
    }

    // Dropdown style tipo collapse
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: $zindex-dropdown;
      //display: none; // none by default, but block on "open" of the menu
      float: left;
      min-width: $dropdown-min-width;
      padding: $dropdown-padding-y 0 0;
      margin: $dropdown-spacer 0 0; // override default ul
      font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
      color: $body-color;
      text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
      list-style: none;
      background-color: $dropdown-bg;
      background-clip: padding-box;
      border: $dropdown-border-width solid $dropdown-border-color;
      @include border-radius($dropdown-border-radius);
      @include box-shadow($dropdown-box-shadow);
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &.show {
        animation: dropdownFadeIn forwards $dropdown-menu-animation-speed;
        top: calc(100% - #{$dropdown-menu-vertical-shift});
      }

      &:before {
        display: block;
      }

      .link-list-wrapper {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;

        ul {
          li {
            a {

              span, i {
                color: $color-primary;
              }

              h3 {
                color: $color-primary;
              }
            }

            h3 {
              color: $body-color;
            }
          }
        }
      }
    }

    .dropdown-toggle {
      display: block;
      justify-content: inherit;
    }
  }
  .custom-navbar-toggler {
    display: none;

  }
  .has-megamenu {
    .navbar-collapsable {
      width: 100%;
    }
  }
  .nav-item.megamenu {
    position: static;

    > a.dropdown-toggle {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: auto;
        bottom: -$dropdown-menu-notch-base-size - 6;
        left: $dropdown-menu-notch-position-x;
        width: $dropdown-menu-notch-base-size*3;
        height: $dropdown-menu-notch-base-size*3;
        border-radius: $dropdown-menu-radius;
        background-color: $dropdown-bg;
        transform: rotate(45deg);
        opacity: 0;
        display: block;
        transition: opacity $dropdown-menu-animation-speed/2 cubic-bezier(.1, .57, .36, .99);

      }
    }

    &.show {
      a.dropdown-toggle {
        &:before {
          opacity: 1;
        }
      }
    }
  }
  .megamenu {
    .dropdown-menu {
      min-width: auto;
      left: $navigation-h-padding;
      right: $navigation-h-padding;
      animation-timing-function: cubic-bezier(.1, .57, .36, .99);
      //hide arrow
      &:before {
        display: none;
      }

      &.show {
        animation: megamenuFadeIn forwards $dropdown-menu-animation-speed/2;
      }
    }
  }
}


//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}
