//mobile
.it-header-wrapper {
  .it-nav-wrapper {
    position: relative;
    // se ha un menu
    .it-brand-wrapper {
      padding-left: $v-gap*4;
    }
    .it-header-navbar-wrapper {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -$header-nav-button-distance;
      nav {
        padding-left: $header-general-padding - ($grid-columns/2);
        padding-right: $header-general-padding - ($grid-columns/2);
        .custom-navbar-toggler {
          padding: 0;
          transform: translateX(-2px);
          cursor: pointer
        }
      }
    }
  }
}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {

}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-wrapper {
    .it-nav-wrapper {
      .it-brand-wrapper {
        padding-left: 0;
      }
      position: relative;
      .it-header-navbar-wrapper {
        position: inherit;
        left: auto;
        top: inherit;
        margin-top: 0;
        nav {
          padding-left: $header-general-padding - ($grid-columns/2);
          padding-right: $header-general-padding - ($grid-columns/2);
        }
      }
    }
    .navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu {
      left: 0;
      right: 0;
    }
  }
}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}
