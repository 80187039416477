//mobile

//indetrminate animation
@keyframes progressBarIndeterminate {
	0% {left:-5%;}
	50% {width: 66%}
	100% {
		left:100%;
		width:33%;
	}
}

//background bar
.progress {
	height: 4px;
	box-shadow: none;
	background-color: lighten($primary-a1,4);
	&.progress-color {
		background-color: $neutral-2;
	}
	&.progress-indeterminate {
		position: relative;
		.progress-bar {
			width: 0;
			animation: progressBarIndeterminate 1.4s cubic-bezier(0.77, 0, 0.175, 1) infinite forwards;
			position: absolute;
			top: 0;
			bottom: 0;
		}
	}
}

//progress bar
.progress-bar {
	background-color: $primary;
}

//label
.progress-bar-label {
	text-align: right;
	font-size: 0.750rem;
	color: $dark;
	font-weight: 500;
}

//button version
.btn-progress {
	.progress {
		display: block;
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
		border-radius: 0 0 $btn-border-radius $btn-border-radius;
	}
	.progress-bar {
		height: 4px;
	}
}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {

	//label
	.progress-bar-label {
		font-size: 0.667rem;
	}

}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {

}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}
