.password-icon {
  cursor: pointer;
  position: absolute;
  right: $v-gap*2;
  top: 1.9rem;
  z-index: 10;
  .icon {
    fill: $gray-secondary;
  }
}

/* Password Strength Meter */
.psw-wrapper  {
  padding: 0 .75rem;
}
.psw-meter  {
  height: 4px;
  max-width: 180px;
  .col-3 {
    height: 4px;
  }
}
.psw-percent,
.psw-text {
  font-size: 0.75rem;
  display: block;
  padding: .25rem 0;
  font-weight: 500;
}
.psw-percent {
  margin-right: 5px;
}
