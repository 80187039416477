.card-wrapper{
	padding-bottom: $v-gap*3;
	display: flex;
}
.card{
	border:none;
	padding: 0;
	position: relative;
	width: 100%;
	.card-body{
		padding:$card-padding;
		h5{
			&.card-title{
				font-size:$card-h5-size;
				line-height: $card-h5-line-height;
				font-weight: $card-h5-fw;
				color: $card-h5-color;
				margin-bottom: $v-gap*2;
				transition: all 0.3s;
				&.big-heading{
					font-size:$card-big-head-size;
					line-height:$card-big-head-l-h;
				}
			}
		}
		h6.card-subtitle{
			font-weight: normal;
			margin-bottom: $v-gap*2;
			margin-top: -$v-gap*2;
		}
		a{
			h5.card-title{
				color: $card-link-color;
			}
		}
		p{
			font-family: $font-family-serif;
			font-size: $card-p-size;
			line-height:$card-p-l-h;
			color: $card-p-color;
		}
		.card-signature{
			font-style: italic;
			font-family: $font-family-serif;
			color: $card-p-color;
			font-weight: 700;
			font-size: $card-signature-size;
			margin-bottom: $v-gap*3;
			display: block;
		}
		.category-top{
			font-size: $card-category-size;
			text-transform: uppercase;
			color: $card-p-color;
			margin-bottom: $card-category-m-bottom;
			a.category{
				color: $card-p-color;
				&:hover{
					text-decoration: underline;
				}
			}
			.category{
				font-weight: bold;
				letter-spacing: $card-category-l-spacing;
			}
			.data{
				&:before{
					content: '—';
					display: inline-block;
					margin: 0 $v-gap;
				}
			}
		}
	}
	// line
	&:after{
		content: '';
		height: $card-small-line-height ;
		width: $card-small-line-w;
		background: $card-small-line-color;
		display: block;
		margin-top: $card-small-line-margin;
		margin-left: $card-padding;
	}
	a.read-more{
		position: absolute;
		bottom: $v-gap*4;
	}
	.categoryicon-top{
		margin-bottom: $card-cat-icon-block-margin;
		display: flex;
		align-items: center;
		.text{
			font-size: $card-category-size;
			text-transform: uppercase;
			letter-spacing: $card-category-l-spacing;
			color: $card-p-color;
			line-height: 1rem;
		}
		.icon{
			width: $card-cat-icon-size;
			height: $card-cat-icon-size;
			fill:$card-link-color;
			margin-right: $v-gap*1;
			flex-shrink: 0;
		}
	}
	// simple link
	.simple-link{
		font-weight: 600;
		font-size: $card-signature-size;
		margin-top: $card-simple-link-margin;
		display: block;
	}
	// cards with background
	&.card-bg{
		margin-left: $card-padding/3;
		margin-right: $card-padding/3;
		box-shadow: $card-shadow;
		background: $card-shadow-bg;
		&:after{
			background: transparent;
		}
		border-right:none;
	}
	// cards with img top
	&.card-img{
		margin-left: $card-padding/3;
		margin-right: $card-padding/3;
		&:after{
			//display: none;
		}
		h5.card-title{
			font-size: $card-img-heading-size;
			line-height: $card-img-heading-l-h;
		}
		border-right:none;

		.img-responsive-wrapper{
			.img-responsive{
				padding-bottom: 61.29%; /* image proportion 310 x 190 */
				.img-wrapper{
				}
			}
		}
	}
	&.no-after{
		&:after{
			display: none;
		}
		a.read-more{
			position: inherit;
		}
	}
	// special card
	&.special-card{
		.img-responsive-wrapper{
			overflow: visible;
			position: relative;
			width: $special-card-img-width;
			margin-bottom: $card-padding;
			&::before,&::after{
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				display: block;
			}
			&:before{
				background: $primary-a3;
				left:$card-padding;
				top:$card-padding;
				opacity: 0.3;
				transition: all 0.3s;
			}
			&:after{
				background: $primary-a3;
				left:$card-padding/2;
				top:$card-padding/2;
				transition: all 0.3s;
			}
			.img-responsive{
				padding-bottom: 122.98%; /* image proportion 214 x 174 */
				z-index: 1;
				.img-wrapper{
				}
			}
		}
		.head-tags{
			margin-bottom:$card-padding;
			span.data{
				font-weight: normal;
			}
		}
		&:hover{
			text-decoration: none;
			h5{
				text-decoration: underline;
				color: $primary-a6;
			}
			.img-responsive-wrapper{
				&:before{
					background: $primary-a6;
					opacity: 0.3;
				}
				&:after{
					background: $primary-a6;
				}
			}
		}
	}
	// card big
	&.card-big{
		.card-body{
			padding:$card-padding * 2;
			.top-icon{
				margin-bottom: $card-padding;
				.icon{
					width: $card-big-top-icon-size;
					height: $card-big-top-icon-size;
					fill: $primary-a6;
				}
			}

			h5.card-title{
				font-size: $card-big-h5-size;
				line-height: $card-big-h5-l-h;
			}
			p{
				font-size:$card-big-p-size;
				line-height: $card-big-p-l-h;
			}
		}
		.flag-icon{
			margin-left:$card-padding * 2;
		}
		.etichetta{
			position: absolute;
			right: $card-padding * 2;
			top: $card-padding + $v-gap;
			left:$card-padding * 6;
			justify-content: flex-end;
		}
	}
	&.border-bottom-card{
		&::before{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 4px;
			bottom:0;
			background: $primary-a6;
		}
	}
	//head tag
	.head-tags{
		justify-content: space-between;
		display: flex;
		margin-bottom: $card-padding*2;
		.data{
			font-size: $card-category-size;
			text-transform: uppercase;
			color: $card-p-color;
		}
	}
	.card-tag{
		color: $primary-a6;
		font-size:$card-category-size;
		font-weight: bold;
		letter-spacing: $card-category-l-spacing;
		padding:0 $v-gap*4;
		border-radius: $tag-radius;
		border:1px solid $primary-a6;

	}
	// card footer
	.it-card-footer{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: $card-padding*2;
		.card-signature{
			font-style: italic;
			font-family: $font-family-serif;
			color: $card-p-color;
			font-weight: 700;
			font-size: $card-signature-size;
			display: block;
			margin-bottom: 0;
		}
	}
}
// flag
.flag-icon {
	width: $flag-icon-w;
	height:  $flag-icon-h;
	box-sizing: content-box;
	position: relative;
	background: $flag-icon-color;
	color:$card-shadow-bg;
	text-align: center;
	text-transform: uppercase;
}
.flag-icon:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	height: 0;
	border-bottom: 13px solid $card-shadow-bg;
	border-left: $flag-icon-w/2 solid transparent;
	border-right: $flag-icon-w/2 solid transparent;
}

// read more text
a.read-more{
	display: flex;
	align-items: center;
	color: $card-link-color;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: $card-category-l-spacing;
	font-size: $card-category-size;
	.icon{
		margin-left: $v-gap;
		fill:$card-link-color;
		width:$card-link-icon-size ;
		height:$card-link-icon-size ;
		flex-shrink: 0;
	}
}
//.etichetta
.etichetta{
	display: flex;
	align-items: center;
	font-size: $card-category-size;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: $card-category-l-spacing;
	color: $card-p-color;
	.icon{
		fill: $card-p-color;
		width: $v-gap*3;
		height: $v-gap*3;
		margin-right: $v-gap;
		flex-shrink: 0;
	}
}
// Contextual variation
.row{
	[class*="col-"]{
		//car variation inside cols
		>.card-wrapper{
			height: 100%;
			> .card{
				height: 100%;
			}
		}
		&:last-child{
			>.card-wrapper{
				> .card{
					border-right: 1px solid transparent;
				}
			}
		}
	}
}



//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {

}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
	.card-wrapper{
		padding-bottom: 0;
		&.card-space{
			padding-bottom: $card-padding/2;
		}
	}
  .card{
		border:none;
		border-right: 1px solid $card-border-color;
		margin-right: -$grid-gutter-width/2;
		&.card-bg,&.card-img{
			margin: 0;
		}
		.card-body{
			p{
				font-size:$card-p-size-desk;
			}
			h5.card-title{
				font-size:$card-h5-size-desk;
			}
		}
	}
}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}
