//mobile

.progress-donut-wrapper {
	width: 128px;
	height: 128px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		transform: scale(.85);
		background: lighten($primary-a1,4);
		border-radius: 50%;
	}
}

.progress-donut {
	transform: scale(0.355);
	position: absolute;
	top: -162px;
	left: -162px;
	.clProg {
		font-weight: 700;
	}
}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {

}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {

	.progress-donut-wrapper {
		width: 180px;
		height: 180px;
	}

	.progress-donut {
		transform: scale(0.5);
		top: -136px;
		left: -136px;
	}

}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {

}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}
