// Custom functions (not available in Bootstrap)
@function hsb($h-hsb, $s-hsb, $b-hsb) {
  // Checking hsb values for 0 and turning them into 1 to avoid issues with SASS compiler
  @if $h-hsb < 1 {
    $h-hsb : 1
  }
  @if $s-hsb < 1 {
    $s-hsb : 1
  }
  @if $b-hsb < 1 {
    $b-hsb : 1
  }

  @if $b-hsb < 1 {
    @return hsla(0, 0, 0, $a)
  } @else {
    $l-hsl: ($b-hsb/2) * (2 - ($s-hsb/100));
    $s-hsl: ($b-hsb * $s-hsb) / if($l-hsl < 50, $l-hsl * 2, 200 - $l-hsl * 2);
    @return hsl($h-hsb, $s-hsl, $l-hsl);
  }
}

@function hsb-with-brightness-modifier($h-hsb, $s-hsb, $b-hsb, $b-variant) {
  $b-hsb: $b-hsb + $b-variant;
  @return hsb($h-hsb, $s-hsb, $b-hsb);
}

@function color-hover($color) {
  @return desaturate( darken( $color, 10% ), 10%);
}

