$input-height: 2.5rem;
$input-spacing-x: .75rem;
$input-spacing-t: 1.5rem;
$input-spacing-b: 1rem;

.form-group {
  position: relative;
  margin-bottom: 4px;
  padding: $input-spacing-t 0 $input-spacing-b;
  input, button, optgroup, textarea {
    color: $dark;
  }
  label {
    position: absolute;
    color: $gray-secondary;
    font-weight: 600;
    line-height: $input-height;
    transition: .2s ease-out;
    top: $input-spacing-t;
    font-size: 1rem;
    cursor: text;
    display: block;
    width: 100%;
    padding: 0 $input-spacing-x;
    z-index: 6;
  }
  small.form-text {
    position: absolute;
    margin: 0;
    padding: 0 $input-spacing-x;
    font-size: $small-font-size;
  }
  &.active {
    label {
      transform: translateY(-75%);
      font-size: $small-font-size;
    }
    label, .ico-prefix {
      color: $gray-secondary;
    }
  }
}
.form-row .form-group {
	padding-bottom: 0;
}

input[type="date"], input[type="datetime-local"], input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], textarea {
  border: none;
  border-bottom: 1px solid $gray-secondary;
  border-radius: 0;
  outline: 0;
  height: $input-height;
  width: 100%;
  box-shadow: none;
  transition: all .3s;
  font-weight: 700;
}
//select,
textarea {
  border: 1px solid $gray-secondary;
}
input[type="file"] + label {
  background-color: transparent;
}


.input-group + label,
.input-group ~ label,
:disabled .form-group label,
.form-group label.active,
//.form-group select.form-control + label,
.form-group input.form-control-file + label,
.form-control-plaintext + label {
  transform: translateY(-75%);
  font-size: $small-font-size;
  background: transparent;
}
:disabled .form-group label {
  top: 0;
}
.form-group.active .form-file-name {
  padding-bottom: 1.95rem
}
.form-control-plaintext {
  padding: 0.375rem 0.75rem;
  background-color: #FFFFFF;
}
.form-control:focus, .form-control:active {
  box-shadow: none !important;
}

 .form-control.is-valid, .was-validated .form-control:valid {
  background-position: center right !important;
  background-repeat: no-repeat !important;
  background-size: 45px 45% !important;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300cc85' viewBox='0 0 192 512'%3E%3Cpath d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
}
 .form-control.is-invalid, .was-validated .form-control:invalid {
  background-position: center right !important;
  background-repeat: no-repeat !important;
  background-size: 45px 45% !important;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f73e5a' viewBox='0 0 384 512'%3E%3Cpath d='M231.6 256l130.1-130.1c4.7-4.7 4.7-12.3 0-17l-22.6-22.6c-4.7-4.7-12.3-4.7-17 0L192 216.4 61.9 86.3c-4.7-4.7-12.3-4.7-17 0l-22.6 22.6c-4.7 4.7-4.7 12.3 0 17L152.4 256 22.3 386.1c-4.7 4.7-4.7 12.3 0 17l22.6 22.6c4.7 4.7 12.3 4.7 17 0L192 295.6l130.1 130.1c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17L231.6 256z'/%3E%3C/svg%3E");
}
.form-control.warning {
  background-position: center right !important;
  background-repeat: no-repeat !important;
  background-size: 25px 45% !important;
  border-color: #ff9900;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff9900' viewBox='0 0 192 512'%3E%3Cpath d='M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z'/%3E%3C/svg%3E");
}
.form-control.is-valid~.warning-feedback {
  display: block;
}
.warning-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .777rem;
  color: #ff9900;
}
.valid-feedback, .invalid-feedback, .warning-feedback {
  margin-left: .75rem;
}

/* INPUT FILE */
.form-file {
  input[type="file"] {
    filter: alpha(opacity=0);
    margin: 0;
    max-width: 100%;
    opacity: 0;
  }
  .form-file-name {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    color: #464a4c;
    left: 0;
    line-height: 1.7;
    overflow: hidden;
    padding: 0.5rem .6rem 2rem;
    max-height: 2rem;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    user-select: none;
    z-index: 5;
  }
  .form-file-name::before {
    background-color: #fff;
    border-color: $primary;
    bottom: -1px;
    color: $primary;
    content: "Sfoglia";
    display: block;
    height: $input-height;
    line-height: 1.7;
    padding: 0.4rem 1rem 1.5rem;
    border: 1px solid $primary;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 6;
    border-top-right-radius: 4px;
  }
}

/* INPUT GROUP */
.input-group-text {
  background-color: #f8f8f8;
  border: 0;
  border-radius: 0 !important;
  border-bottom: 1px solid #555;
}
.input-group-prepend {
  margin-right: 0px;
}
.input-group-append {
  margin-left: 0px;
}
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0rem 1rem;
  border-radius: 0;
}

/* ICONE A SINISTRA */ // TODO non usare .ico-prefix
.ico-prefix {
	position: absolute;
	width: 3rem;
	font-size: 1.8rem;
	z-index: 10;
	text-align: center;
  ~ .form-file-name {
    padding-left: 3.5rem;
  }
  ~ input, ~ textarea, ~ label {
    padding-left: 3rem;
  }
  ~ label.active {
    padding-left: .75rem;
  }
}
.form-group.active .ico-prefix ~ label {
  padding-left: .75rem;
}

/* CHECKBOX e RADIO */
.form-row .form-check,
.form-group .form-check,
.row .form-check {
  padding-left: 0;
  margin-top: 1rem;
}

.form-check {
  [type="checkbox"],
  [type="radio"] {
    position: absolute;
    left: -9999px;
    + label {
      position: relative;
      padding-left: 36px;
      cursor: pointer;
      display: inline-block;
      height: 32px;
      line-height: 32px;
      font-size: 1rem;
      user-select: none;
    }
  }
  [type="checkbox"] {
    + label::after,
    + label::before {
      content: '';
      left: 0;
      position: absolute;
      transition: .2s;
      z-index: 1;
      border-style: solid;
      border-width: 2px;
    }
    + label::before {
      top: 0;
      width: 17px;
      height: 17px;
      border: 1px solid #e6e9f2;
      border-radius: 1px;
      margin: 2px 5px;
      transform: rotateZ(37deg);
      transform-origin: 100% 100%;
    }
    + label::after {
      border-radius: 4px;
      height: 20px;
      width: 20px;
      margin: 6px 5px;
      top: 0;
    }
    &:checked + label::before {
      top: 6px;
      left: 1px;
      width: 8px;
      height: 13px;
      border-style: solid;
      border-width: 2px;
      border-color: transparent #fff #fff transparent;
      transform: rotate(40deg);
      backface-visibility: hidden;
      transform-origin: 100% 100%;
      opacity: .8;
    }
    &:checked + label::after {
      border-color: $primary;
      background-color: $primary;
      z-index: 0;
    }
    &:not(:checked) + label::after {
      background-color: transparent;
      border-color: #5C6F82;
      z-index: 0;
    }
    &:not(:checked) + label::before {
      width: 0;
      height: 0;
      border-color: transparent;
      left: 6px;
      top: 10px;
    }
    &:disabled + label {
      cursor: not-allowed;
    }
    &:disabled:not(:checked) + label::after {
      border-color: #E6E9F2;
      background-color: #FFF;
    }
    &:disabled:checked + label::after{
      background-color: #E6E9F2;
      border-color: #E6E9F2;
    }
    //focus
    &:focus + label{
      @extend .focus--keyboard;
    }

    &:focus.focus--mouse + label {
      @extend .focus--mouse;
    }
  }

  [type="radio"] {
    + label {
      transition: .2s ease;
    }
    + label::after,
    + label::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: 5px;
      width: 22px;
      height: 22px;
      z-index: 0;
      border-radius: 50%;
      border-style: solid;
      border-width: 2px;
      transition: .2s ease;
    }
    &:not(:checked) + label::after,
    &:not(:checked) + label::before {
      border-color: #5C6F82;
    }
    &:not(:checked) + label::after {
      z-index: -1;
      transform: scale(0);
    }
    &:checked + label::after {
      border-color: $primary;
      background-color: $primary;
      z-index: 0;
      transform: scale(.64);
    }
    &:checked + label::before {
      border-color: $primary;
    }
    &:disabled + label {
      cursor: not-allowed;
    }
    &:disabled:not(:checked) + label::after,
    &:disabled:not(:checked) + label::before {
      border-color: #E6E9F2;
    }
    &:disabled:checked + label::after {
      border-color: #E6E9F2;
      background-color: #E6E9F2;
    }
    &:disabled:checked + label::before {
      border-color: #E6E9F2;
    }
  }

  // gruppi
	&.form-check-group {
		padding: 0 0 8px 0;
		margin-bottom: 16px;
		box-shadow: inset 0 -1px 0 0 rgba(1,1,1,0.1);
		[type="checkbox"] + label,
		[type="radio"] + label {
			position: static;
			padding-left: 8px;
			padding-right: 52px;
		}
		[type="checkbox"] + label::after,
		[type="checkbox"] + label::before,
		[type="radio"] + label::after,
		[type="radio"] + label::before {
			right: 15px;
			left: auto;
		}
		[type="checkbox"]:checked + label::before {
			right: 26px;
		}
		[type="radio"]:checked + label::before {
			right: 15px;
		}
		.form-text {
      opacity: .6;
      margin: 0;
      padding-left: 8px;
      padding-right: 52px;
		}
	}
}

/* TOGGLES */
.toggles,
.toggles * {
  appearance: none;
  user-select: none;
}

.toggles label {
  cursor: pointer;
  width: 100%;
  margin: 0 8px 8px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;

  input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
    &:focus + .lever {
      @extend .focus--keyboard;
    }
    &:focus.focus--mouse + .lever {
      @extend .focus--mouse;
    }
    & + .lever {
      content: "";
      position: relative;
      width: 46px;
      height: 16px;
      background-color: #e6e9f2;
      border-radius: 10px;
      margin-right: 10px;
      transition: background 0.3s ease;
      vertical-align: middle;
      margin: 0 16px;
      float: right;
      margin-top: 8px;

      &:before, &:after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        left: 0;
        top: -5px;
        left: -3px;
        transition: left 0.3s ease, background .1s ease, transform .1s ease;
      }

      &:before {
        background-color: transparentize($primary, .85);
      }

      &:after {
        background-color: #5c6f82;
        background-size: 10px 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.8'%3e%3cg id='Data-entry/toggle/Light_bg/disabledOff' transform='translate(-8.000000, -11.000000)' fill='%23FFFFFF'%3e%3cpath d='M14.363961,15.9497475 L17.8994949,19.4852814 L16.4852814,20.8994949 L12.9497475,17.363961 L9.41421356,20.8994949 L8,19.4852814 L11.5355339,15.9497475 L8,12.4142136 L9.41421356,11 L12.9497475,14.5355339 L16.4852814,11 L17.8994949,12.4142136 L14.363961,15.9497475 Z' id='Combined-Shape'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");      border: 2px solid #fff;
      }
    }

    &:checked + .lever {
      background-color: #e6e9f2;

      &:before, &:after {
        left: 23px;
      }

      &:after {
        background-color: $primary;
        background-size: 14px 14px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!-- Generator: Sketch 49.2 (51160) - http://www.bohemiancoding.com/sketch --%3e%3cdesc%3eCreated with Sketch.%3c/desc%3e%3cdefs%3e%3c/defs%3e%3cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.5'%3e%3cg id='Data-entry/toggle/Light_bg/on' transform='translate(-28.000000, -11.000000)' fill='%23FFFFFF'%3e%3cg id='Group-3-Copy-5' transform='translate(24.000000, 16.000000) scale(-1, 1) translate(-24.000000, -16.000000) translate(1.000000, 4.000000)'%3e%3cpath d='M13,15 L13,5 L15,5 L15,15 L15,17 L9,17 L9,15 L13,15 Z' id='Combined-Shape' transform='translate(12.000000, 11.000000) scale(-1, 1) rotate(45.000000) translate(-12.000000, -11.000000) '%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
      }
    }

    &[disabled] + .lever {
      cursor: default;
      background-color: #e6e9f2;
      &:after {
        background-color: #e6e9f2;
      }
    }

    &[disabled]:checked + .lever:after {
      background-color: #e6e9f2;
    }
  }
}

/* SELECT
.select-wrapper {
  position: relative;
  .search-wrap input {
    width: 100%;
    border: none;
    border-bottom: 1px solid $primary;
    font-size: .9rem;
    padding: 0 .5em;
  }
  .dropdown-menu {
    background-color: #fff;
    border-radius: 0px;
    box-shadow: 0 2px 4px 3px rgba(0,0,0,0.1);
    margin: 0;
    display: none;
    min-width: 100px;
    max-height: 240px;
    overflow-y: auto;
    position: absolute;
    padding: .3rem;
    z-index: 999;
    will-change: width, height;
    list-style-type: none;
    user-select: none;
    width: 100%;
    &.show {
      display: block;
    }
    li {
      clear: both;
      color: #000;
      cursor: pointer;
      line-height: 32px;
      width: 100%;
      text-align: left;
      text-transform: none;
      &.optgroup {
        > span {
          color: #CCC;
          padding: 0;
        }
      }
      &:not(.disabled):hover,
      &:not(.disabled):focus {
        background-color: #eee;
      }
      &.active {
        > span::after {
          content: '';
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNDQ4LjggNDQ4LjgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ0OC44IDQ0OC44OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGcgaWQ9ImNoZWNrIj4KCQk8cG9seWdvbiBwb2ludHM9IjE0Mi44LDMyMy44NSAzNS43LDIxNi43NSAwLDI1Mi40NSAxNDIuOCwzOTUuMjUgNDQ4LjgsODkuMjUgNDEzLjEsNTMuNTUgICAiIGZpbGw9IiMwMDZERjAiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K");
          background-size: 16px 16px;
          background-repeat: no-repeat;
          background-position: center center;
          float: right;
          display: block;
          height: 32px;
          width: 16px;
          opacity: .5;
        }
      }
      > a,
      > span {
        font-size: 14px;
        color: $gray-secondary;
        font-weight: 600;
        display: block;
        padding: 0 .5rem;
        &.filtrable label, [type=checkbox] {
          display: none;
        }
      }
      &.disabled {
        > span {
          cursor: unset;
          color: #CCC;
        }
      }
      > a > i {
        height: inherit;
        line-height: inherit; }
    }
  }
  .select-dropdown {
    color: $dark;
    border-bottom: 1px solid $gray-secondary;
    padding-right: 2rem; // TODO
    margin-bottom: 0; // TODO
    position: relative;
    cursor: pointer;
    background-color: transparent;
    outline: 0;
    width: 100%;
    font-size: 1rem;
    margin: 0 0 15px 0;
    display: block;
    line-height: 24px;
    padding: 12px 38px 12px 16px;
    &:disabled {
      color: rgba(0, 0, 0, .3);
      border-bottom-color: rgba(0, 0, 0, .3);
      cursor: default
    }
    ul {
      list-style-type: none;
      padding: 0;
      li {
        &.disabled,
        &.disabled > span,
        &.optgroup {
          color: rgba(0, 0, 0, .3);
          background-color: transparent !important;
          cursor: context-menu
        }
        &.optgroup {
          border-top: 1px solid #eee;
          &.selected > span {
            color: rgba(0, 0, 0, .7);
          }
          & > span {
            color: rgba(0, 0, 0, .4);
          }
        }
      }
    }
  }
  .icon {
    fill: #757575;
    position: absolute;
    right: 12px;
    line-height: 16px;
    top: calc(50% - 8px);
    transition: all $dropdown-menu-animation-speed;
    .disabled {
      color: rgba(0, 0, 0, .46);
    }
  }
  &.show {
    .select-dropdown {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
    .icon {
      fill: $primary;
      transform: scaleY(-1);
    }
  }
  & + label {
    padding: 0 .75rem !important;
    position: absolute;
    top: -18px;
    font-size: .8rem;
  }
  select.custom-select {
    display: none !important;
  }
  select:disabled {
    color: rgba(0, 0, 0, .3);
  }
}
*/
