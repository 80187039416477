//mobile
.it-header-slim-wrapper{
	background: $header-slim-bg-color;
	padding:$header-slim-v-padding-mob $header-general-padding - ($grid-columns/2); 
	.it-header-slim-wrapper-content{
		display:flex;
		justify-content: space-between;
		//align-items: center;
		.dropdown-menu{
			top: -$header-slim-dropdown-distance-mob !important;
		}
		a{
			color: $header-slim-text-color;
			&:hover:not(.active){
				text-decoration: underline;
			}
			.icon{
				fill: $header-slim-text-color;
				width: $header-slim-icon-size;
				height: $header-slim-icon-size;
				transition: all 0.3s;
				transform-origin: center;
			}
			&.dropdown-toggle{
				text-transform: uppercase;
				font-size: $header-slim-brand-text-size;
				text-decoration: underline;
				display: flex;
				align-items: center;
			}
			&.it-opener{
				font-size: $header-slim-brand-text-size;
				padding:.5rem 0;
				display: block;
				text-decoration: none;
				&[aria-expanded="true"]{
					.icon{
						transform: scaleY(-1);
					}
				}
			}
		}
		.navbar-brand{
			color: $header-slim-text-color;
			font-size: $header-slim-brand-text-size;
			padding: 0;
		}
		.header-slim-right-zone{
			display: flex;
			align-items: center;
			align-self: flex-start;
			button{
				background: $header-slim-button-color;
				padding-top: $header-slim-button-v-padding;
				padding-bottom: $header-slim-button-v-padding;
				&:hover{
					background: $header-slim-button-hover-color;
				}
			}
		}
		
		span.nav-palce-mobile{
			ul.link-list{
				margin-top: $v-gap*2;
				margin-bottom: $v-gap*3;
				a{
					&.active{
						text-decoration: underline;
					}
					&:hover:not(.active){
						text-decoration: underline;
					}
				}
			}
		}
	}
}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {
  
}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {
	
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
	.it-header-slim-wrapper{
		height: $header-slim-height;
		.it-header-slim-wrapper-content{
			padding:0 $header-slim-h-padding;
			height: $header-slim-height;
			.dropdown-menu{
				top: -$header-slim-dropdown-distance !important;
			}
			span.nav-palce-mobile{
				flex-grow: 2;
				ul.link-list{
					margin: 0;
					display: flex;
					padding:0 $v-gap*3;
					margin-right: $v-gap*2;
					border-left:$header-slim-ul-border;
					border-right: $header-slim-ul-border;
					height: $header-slim-height;
					a{
						padding-top:$header-slim-links-padding;
						padding-bottom:$header-slim-links-padding;
						&.active{
							border-bottom:$header-slim-a-active-border;
							text-decoration: none;
						}
					}
				}
			}
			a{
				&.dropdown-toggle{
					padding-top: $header-slim-lang-v-padding;
					padding-bottom: $header-slim-lang-v-padding;
					text-decoration: none;
					.icon{
						margin-left: $v-gap/2;
					}
				}
				&.navbar-brand{
					padding-top: $header-slim-brand-padding;
					padding-bottom: $header-slim-brand-padding;
				}
			}
			.header-slim-right-zone{
				height: $header-slim-height;
			}
		}
		
		
		padding:0;
		.it-header-slim-wrapper-content{
			align-items: center;
		}
		.collapse:not(.show){
			display: flex;
			justify-content: flex-end; 
		}
		
	}
}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}
