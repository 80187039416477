//mobile
.it-timeline-wrapper{
	.it-now-label{
		position: absolute;
		top: 50%;
		z-index: 10;
		font-size: 1rem;
		font-family: $font-family-monospace;
		color: $primary-a6;
		font-weight: 600;
	}
	.row{
		position: relative;
		padding-top: $v-gap*6;
		//padding-bottom: $v-gap*6;
		&:after{
			content:'';
			width: $timeline-width;
			background: $timeline-border-color;
			position: absolute;
			left:$v-gap*5;
			top:-$v-gap*2;
			bottom:-$v-gap*2;
		}
	}
	.timeline-element{
		padding: $timeline-content-padding;
	}
	.it-pin-wrapper{
		display: flex;
		align-items: center;
		position: absolute;
		left: 0;
		top: -$timeline-pin-circle-size/2;
		z-index: 5;
		left:$timeline-padding;
		.pin-icon{
			width: $timeline-pin-circle-size;
			height: $timeline-pin-circle-size;
			border-radius: $timeline-pin-circle-size;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: $card-shadow;
			border:$timeline-pin-circle-border;
			margin-right: $v-gap*2;
			background: #FFF;
		}
		svg{
			width: $timeline-pin-size;
			height: $timeline-pin-size;
			fill: $primary-a6;
		}
		.pin-text{
			color: $select-label-color;
			font-weight: 600;
			text-transform: uppercase;
			background: $primary-c1;
			border-radius: $border-radius;
			font-family: $font-family-monospace;
			
			span{
				padding:$v-gap/2 $v-gap*2; 
				display: block;
				position: relative;
				&:after{
					content: '';
					width: 10px;
					height: 10px;
					background:$primary-c1;
					border-radius: 3px;
					position: absolute;
					left:-4px;
					top: 50%;
					transform: translateY(-50%) rotate(45deg);
				}
			}
		}
		&.it-now{
			.pin-icon{
				background: $primary-a6;
			}
			svg{
				fill: #FFF;
			}
			.pin-text{
				color: #FFF;
				background: $primary-a6;
				span{
					&:after{
						background:  $primary-a6;  
					}
				}
			}
		}
		&.it-evidence{
			.pin-icon{
				background: $primary-a10;
			}
			svg{
				fill: #FFF;
			}
			.pin-text{
				color: #FFF;
				background: $primary-a10;
				span{
					&:after{
						background:  $primary-a10;  
					}
				}
			}
		}
	}
}


//small - tablet
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
	.it-timeline-wrapper{
		.row{
			&:after{
				content:'';
				left:calc(50% - #{$timeline-width});
			}
		}
		.timeline-element{
			padding: $timeline-content-padding;
			width: 50%;
		}
		.col-12{
			.card-wrapper{
				.card{
					border: none;
				}
			}
		}
		// col rules
		.col-12:nth-child(odd){
			.it-now-label{
				right:50%;
				transform: translateX(100%);
				display: flex;
				align-items: center;
				&:before{
					content: '';
					display: inline-block;
					height: 1px;
					width: $v-gap*2;
					background: $primary-a6;
					margin-right: $v-gap*2;
				}
			}
			.timeline-element{
				padding:$timeline-content-padding-reverse;
			}
			.card-wrapper{
				.card{
					text-align: right;
					&:after{
						margin-left: auto;
						display: inline-block;
						margin-right: $v-gap*3;
					}
					a.read-more{
						right:$v-gap*3;
					}
				}
			}
			.it-pin-wrapper{
				flex-direction: row-reverse;
				left: auto;
				right: -62px;
				.pin-icon{
					margin-right: 0;
					margin-left:$v-gap*2; 
				}
				.pin-text{
					
					span{
						&:after{
							left: auto;
							right: -4px;
						}
					}
				}
				&.it-evidence{
					.pin-text{
						span{
							&:after{
								border-color:  transparent transparent transparent $primary-a6;  
							}
						}
					}
				}
			}
		}
		.col-12:nth-child(even){
			.it-now-label{
				left:0;
				transform: translateX(-100%);
				display: flex;
				align-items: center;
				&:after{
					content: '';
					display: inline-block;
					height: 1px;
					width: $v-gap*2;
					background: $primary-a6;
					margin-left: $v-gap*2;
				}
			}
			.timeline-element{
				position: relative;
				left: 50%;
				.it-pin-wrapper{
					left: -66px;
				}
			}
		}
		.it-pin-wrapper{
			position: relative;
			.pin-icon{
		
			}
			svg{
			
			}
			.pin-text{
				font-size: 16px;
				span{
					&:after{
						
					}
				}
			}
			&.it-evidence{
				.pin-icon{
				
				}
				svg{
					
				}
				.pin-text{
				
					span{
						&:after{
							
						}
					}
				}
			}
		}
	}
	
}