
div.datepicker-calendar.default button.datepicker-close:focus,
div.datepicker-calendar.default button.datepicker-close:hover,
div.datepicker-calendar.default div.datepicker-month-fast-next.enabled:focus,
div.datepicker-calendar.default div.datepicker-month-fast-next.enabled:hover,
div.datepicker-calendar.default div.datepicker-month-fast-prev.enabled:focus,
div.datepicker-calendar.default div.datepicker-month-fast-prev.enabled:hover,
div.datepicker-calendar.default div.datepicker-month-next.enabled:focus,
div.datepicker-calendar.default div.datepicker-month-next.enabled:hover,
div.datepicker-calendar.default div.datepicker-month-prev.enabled:focus,
div.datepicker-calendar.default div.datepicker-month-prev.enabled:hover,
div.datepicker-calendar.default div.datepicker-month:focus,
div.datepicker-calendar.default div.datepicker-month:hover {
  background: transparent;
  border-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

div.datepicker-calendar div.datepicker-month-fast-next,
div.datepicker-calendar div.datepicker-month-fast-prev,
div.datepicker-calendar div.datepicker-month-next,
div.datepicker-calendar div.datepicker-month-prev {
  margin: 0;
  padding: 0;
}

div.datepicker-calendar.default table.datepicker-grid td.selectable.focus,
div.datepicker-calendar.default table.datepicker-grid td.selectable:hover {
  background-color: transparent;
}

div.datepicker-calendar.default table.datepicker-grid td {
  background: transparent;
  span {
    display: inline-block;
    width: $dp-current-size;
    height: $dp-current-size;
    line-height: $dp-current-size;
    border-radius: 50px;
    text-align: center;
    transition: all 0.3s;
  }
  &:hover {
    background: transparent;
    span {
      background: $neutral-1-a1;
    }
  }
}

div.datepicker-calendar.default table.datepicker-grid td.empty:hover {
  background: transparent;
  span {
    background: $primary-a6;
    color: #FFF;
  }
}

div.datepicker-calendar.default table.datepicker-grid td.selectable.focus {
  background: transparent;
  span {
    background: $primary-a6;
    color: #FFF;
  }
}

div.datepicker-calendar.default table.datepicker-grid td.curDay,
div.datepicker-calendar.default table.datepicker-grid td.curMonth,
div.datepicker-calendar.default table.datepicker-grid td.curYear {
  background: transparent;
  span {
    background: $lightgrey-b1;
  }
}

div.datepicker-calendar.default tr.datepicker-weekdays {
  border: none;
}

div.datepicker-calendar.default table.datepicker-grid th {
  background: transparent;
}

div.datepicker-calendar table.datepicker-grid {
  font-size: $dp-grid-size;
  text-transform: uppercase;
  letter-spacing: $dp-month-l-spacing;
}

abbr[data-original-title], abbr[title] {
  text-decoration: none;
  cursor: default;
}

.it-datepicker-wrapper {
  position: relative;
  // calendar
  input[type="time"] {
    color: $dp-label-color;
  }
  label.pickerlabel {
    color: $gray-secondary;
    font-weight: 600;
    line-height: $input-height;
    transition: .2s ease-out;
    top: $input-spacing-t;
    font-size: $small-font-size;
    cursor: text;
    display: block;
    width: 100%;
    padding: 0 $input-spacing-x;
    z-index: 6;
  }
  .datepicker-calendar {
    box-shadow: $dp-shadow;
    border-radius: 0;
    border: none;
    width: 100%;
    border-top: 2px solid $dp-label-color;
    margin-top: -8px;
    color: $dp-label-color;
    padding: $v-gap*3;
    &[aria-hidden=true] {
      display: inherit;
    }
    .datepicker-month-wrap {
      padding: 0;
      color: $dp-label-color;
      margin-bottom: $v-gap*2;
      .datepicker-month {
        font-size: $dp-month-size;
        letter-spacing: $dp-month-l-spacing;
        text-transform: uppercase;
        &:after {
          display: none;
        }
      }
      .icon-right {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3px 0 3px 6px;
        border-color: transparent transparent transparent #000;
        display: inline-block;
        transform: translateY(-3px);
      }
      .icon-left {
        width: 0; 
        height: 0;
        border-style: solid;
        border-width: 3px 6px 3px 0;
        border-color: transparent #000 transparent transparent;
        display: inline-block;
        transform: translateY(-3px);
      }
      .datepicker-month-fast-next {
        float: right;
        display: inline-block;
        min-width: $v-gap*4;
        svg {
          &:first-child {
            margin-right: -$v-gap;
          }
        }
      }
      .datepicker-month-next {
        float: right;
      }
      .datepicker-month-fast-prev {
        float: left;
        display: inline-block;
        min-width: $v-gap*4;
        svg {
          &:last-child {
            margin-left: -$v-gap;
          }
        }
      }
      .datepicker-month-prev {
        float: left;
      }
    }
    .datepicker-month {
      margin: 0;
      padding: 0;
    }
    tr.datepicker-weekdays {

    }
  }
  .input-group {
    //border-bottom:$dp-border-bottom;
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: $dp-label-color;
      transform-origin: bottom;
      transition: all 0.3s;
      left: 0;
    }
    .it-date-datepicker {
      border-bottom: none;
      background: transparent;
      &::placeholder {
        color: $dp-label-color;
      }
    }
    // button
    .datepicker-button {
      padding: 0;
      all: unset;
      margin-right: $v-gap*2;
      position: relative;
      height: 40px;
      cursor: pointer;
      &:hover {
        all: unset;
        margin-right: $v-gap*2;
        height: 40px;
        .icon {
          fill: $primary-a6;
        }
      }
      .icon {
        fill: $dp-label-color;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s;
      }
    }
  }

}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {

}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {

}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {

}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}
