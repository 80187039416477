//mobile

//basic animation
@keyframes spinnerAnim {
  0% {transform: rotate(0);}
  100% {transform: rotate(360deg);}
}
//double spinner anim 1
@keyframes spinnerAnimInner1 {
	0% {transform: rotate(60deg);}
	100% {transform: rotate(205deg);}
}
//double spinner anim 2
@keyframes spinnerAnimInner2 {
	0% {transform: rotate(30deg);}
	100% {	transform: rotate(-105deg);}
}

//standard spinner
.progress-spinner {
  display:block;
  width:$v-gap*6;
  height:$v-gap*6;
  border-radius:50%;
	border:4px solid lighten($primary-a1,4);
	//spinner active state
	&.progress-spinner-active {
		animation: spinnerAnim 0.75s linear infinite;
	}
	//standard spinner active state
	&.progress-spinner-active:not(.progress-spinner-double) {
		border-color: $primary;
		border-bottom-color: lighten($primary-a1,4);
	}
	//small size
	&.size-sm {
		width:$v-gap*4;
  	height:$v-gap*4;
	}

	//double spinner
	&.progress-spinner-double {
		$size: 48px;
		display: inline-block;
		//small size
		&.size-sm {
			width:$v-gap*4;
			height:$v-gap*4;
			//double spinner inner
			.progress-spinner-inner {
				width: $v-gap*4;
				height: $v-gap*2;
			}
		}
		//double spinner active state
		&.progress-spinner-active {
			.progress-spinner-inner {
				opacity: 1;
			}
		}
		//double spinner inner
		.progress-spinner-inner {
			width: $v-gap*6;
			height: $v-gap*3;
			overflow: hidden;
			margin-left: -$v-gap/2;
			opacity: 0;
			transition: opacity 0.3s;
			&:nth-child(1) {
				margin-top: -$v-gap/2;
			}
			&:nth-child(2) {
				transform: rotate(180deg);
				&:after {
					animation-name: spinnerAnimInner2;
				}
			}
			&:after {
				content: '';
				display: inline-block;
				transform: rotate(45deg);
				border-radius: 50%;
				border: 4px solid $primary;
				border-right: 4px solid transparent;
				border-bottom: 4px solid transparent;
				width: 100%;
				height: 200%;
				animation: spinnerAnimInner1 0.75s cubic-bezier(.25, .1, .5, 1) infinite alternate;
			}
		}
	}
}