.theme-dark{
	div.datepicker-calendar.default button.datepicker-close:focus, div.datepicker-calendar.default button.datepicker-close:hover, div.datepicker-calendar.default div.datepicker-month-fast-next.enabled:focus, div.datepicker-calendar.default div.datepicker-month-fast-next.enabled:hover, div.datepicker-calendar.default div.datepicker-month-fast-prev.enabled:focus, div.datepicker-calendar.default div.datepicker-month-fast-prev.enabled:hover, div.datepicker-calendar.default div.datepicker-month-next.enabled:focus, div.datepicker-calendar.default div.datepicker-month-next.enabled:hover, div.datepicker-calendar.default div.datepicker-month-prev.enabled:focus, div.datepicker-calendar.default div.datepicker-month-prev.enabled:hover, div.datepicker-calendar.default div.datepicker-month:focus, div.datepicker-calendar.default div.datepicker-month:hover{
		background: transparent;
		border-color: transparent;
	}
	div.datepicker-calendar div.datepicker-month-fast-next, div.datepicker-calendar div.datepicker-month-fast-prev, div.datepicker-calendar div.datepicker-month-next, div.datepicker-calendar div.datepicker-month-prev{
	
	}
	div.datepicker-calendar.default table.datepicker-grid td.selectable.focus, div.datepicker-calendar.default table.datepicker-grid td.selectable:hover{
		background-color: transparent;
	}
	div.datepicker-calendar.default table.datepicker-grid td{
		background: transparent;
		span{
			
		}
		&:hover{
			background: transparent;
			span{
				background:  rgba(255,255,255,0.1);
			}
		}
	}
	div.datepicker-calendar.default table.datepicker-grid td.empty:hover{
		background: transparent;
		span{
			background: rgba(255,255,255,0.1);
			color: #FFF;
		}
	}
	div.datepicker-calendar.default table.datepicker-grid td.selectable.focus{
		background: transparent;
		span{
			background: #FFF;
			color:$primary-a6;
		}
	}
	div.datepicker-calendar.default table.datepicker-grid td.curDay, div.datepicker-calendar.default table.datepicker-grid td.curMonth, div.datepicker-calendar.default table.datepicker-grid td.curYear{
		background: transparent;
		span{
			background: rgba(255,255,255,0.1);
		}
	}
	div.datepicker-calendar.default tr.datepicker-weekdays{
	
	}
	div.datepicker-calendar.default table.datepicker-grid th{
		background: transparent;
	}
	div.datepicker-calendar table.datepicker-grid{
		
	}
	abbr[data-original-title], abbr[title]{
		
	}
	div.datepicker-calendar.default table.datepicker-grid td{
		color: #FFF;
	}
	div.datepicker-calendar.default table.datepicker-grid td.empty{
		opacity: 0.3;
	}
}
.theme-dark{
	&.it-datepicker-wrapper{
		// calendar
		.datepicker-calendar{
			border-top:2px solid $dp-label-color;
			color: #FFF;
			background-color: $primary-a6;
			.datepicker-month-wrap{
				color:  #FFF;
				.datepicker-month{
					
					&:after{
						
					}
				}
				svg{
					fill:#FFF; 
				}
				.icon-right {
					border-color: transparent transparent transparent #FFF;
				}
				.icon-left {
					border-color: transparent #FFF transparent transparent;
				}
				.datepicker-month-fast-next{
					
					svg{
						&:first-child{
						 
						}
					}
				}
				.datepicker-month-next{
					float: right;
				}
				.datepicker-month-fast-prev{
					
					svg{
						&:last-child{
						 
						}
					}
				}
				.datepicker-month-prev{
					
				}
			}
			.datepicker-month{
				
			}
			tr.datepicker-weekdays{
			 
			}
		}
		.input-group{
			&:after{
				background:$dp-label-color;
			}
			.it-date-datepicker{
				
				background: transparent;
				&::placeholder{
					color: $dp-label-color;
				}
			}
			// button
			.datepicker-button{
	
				&:hover{
				}
				.icon{
					
				}
			}
		}
		
	}
}

