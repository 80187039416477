.affix-top {
    position: sticky;
    top: 0;
}
.affix-bottom {
    position: sticky;
    bottom: 0;
}
.affix-parent {
    position: relative;
}